body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}
a:hover {opacity: 0.8;}
button:not(:disabled):hover,
.chat__card:not(.chat__card--disabled):hover {
  cursor: pointer;
  scale: 1.02;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.chat__card--disabled {
  cursor: initial;
  pointer-events: none;
}

button, .chat__card {
  transition: scale 0.1s, box-shadow 0.1s;
  cursor: pointer;
}

button:disabled,
a:disabled,
input:disabled,
textarea:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.side-panel__conversations__icon-buttons {
  display: none;
}
.side-panel__conversations li:hover .side-panel__conversations__icon-buttons {
  display: inline-flex;
}

.chat * { font-size: 16px; line-height: 1.5em; }
.chat__prompt-form textarea:focus {
  background-color: rgb(255, 255, 255)
}
.chat__prompt-form textarea::placeholder {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

input.chat__name:focus {
  color: black
}
input.chat__name::placeholder {
  color:grey;
  font-weight: 100;
}
.chat__message--forgotten .message__sender {
  position: relative;
}
.chat__message--forgotten .message__sender::after {
  content: 'zᶻ';
  font-size: 12px;
  position: absolute;
  bottom: 80%;
  left: 80%;
  transform: rotate(180deg)
}
.chat ol, .chat ul {
  list-style-type: revert;
  white-space: initial;
  padding-inline-start: 1.5em;
}
.chat ol:not(:last-child), .chat ul:not(:last-child) {
  margin-bottom: 12px;
}
.chat li {
  list-style-type: revert;
}
.chat li:not(:last-child) {
  margin-bottom: 8px;
}
.chat pre, .chat samp {
  margin-top: 0px;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  border-radius: 16px;
  overflow-x: auto;
  white-space: pre;
  width: 100%;
}

.chat pre:last-child,
.chat samp:last-child {
  margin-bottom: 0
}

.chat p, .chat div, .chat span, .chat li, .chat code {
  font-size: 16px;
  line-height: 1.5em;
  margin-top: 0;
}

.chat code {
  font-size: 14px;
}

.chat pre.input--text,
.chat pre.code-interpreter-input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 0;
  position: relative;
  padding: 0;
}

.chat pre.input--text .caret--code-toggle {
  font-size: 24px;
  line-height: 15px;
  display: block;
  position: absolute;
  top: 6px;
  right: 10px;
  padding: 20px 10px 10px;
  cursor: pointer;
  background: none;
  border: none;
  color: white;
}

.chat pre.input--text.code-block--collapsed {
  height: 56px;
  overflow: hidden;
  border-radius: 16px;
  margin-bottom: 12px;
}

.chat pre.input--text.code-block--collapsed .caret--code-toggle {
  transform: rotate(180deg);
}

.chat pre.input--text.code-block--collapsed + pre.output--logs,
.chat pre.input--text.code-block--collapsed + pre.output--text {
  display: none;
}

.chat pre.input--text + pre.input--text,
.chat pre.code-interpreter-input + pre.code-interpreter-input {
  border-radius: 0;
}

.chat pre.input--text + p,
.chat pre.code-interpreter-input + p {
  margin-top: 1em;
}

.chat pre.input--text code,
.chat pre.code-interpreter-input code {
  display: block;
  padding: 16px;
}

.chat pre.input--text::before,
.chat pre.code-interpreter-input::before {
  content: 'python';
  display: block;
  font-size: 12px;
  background-color: #3b3b3b;
  padding: 16px;
  position: sticky;
  top: 0;
  left: 0;
  /* width: 100%; */
}

.chat pre.output--logs,
.chat pre.output--text,
.chat pre.code-interpreter-output--text,
.chat pre.code-interpreter-output--logs {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: #d1d1d1;
  color: black;
}

.chat pre.output--logs code::before,
.chat pre.output--text code::before,
.chat pre.code-interpreter-output--text code::before,
.chat pre.code-interpreter-output--logs code::before {
  content: 'Result';
  display: block;
  font-size: 12px;
  margin-bottom: 8px;
}

.chat__message-content :first-child {
  margin-top: 0;
}

.chat__message-content :last-child {
  margin-bottom: 0;
}

.chat__message-content img {
  margin: 12px 0 !important;
  width: 100%;
  border-radius: 16px;
}

.chat__message-content p {
  position: relative;
}

.chat__message-content p:hover img {
  filter: brightness(0.85);
}

.chat__message-content img + br {
  display: none;
}

.chat__message-content p:hover img + br + a {
  display: block;
}

.chat__message-content img + br + a {
  display: none;
  position: absolute;
  top: 20px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  padding: 1px 6px;
  text-decoration: none;
  color:white;
}

.chat__message-content a[title="open source file in a new tab"] {
  color: rgb(0, 88, 171);
  text-decoration: none;
}

.chat__message-content a[title="open source file in a new tab"]::after {
  content: '↗';
  margin-left: 4px;
  display: none;
}

.chat__message-content a[title="open source file in a new tab"]:hover::after {
  display: inline;
}

@keyframes dash {
  from {
    stroke-dashoffset: -1500;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.chat__logo path {
  stroke-dasharray: 1500;
  stroke-dashoffset: -1500;
  animation: dash 3s linear alternate infinite;
}

@keyframes travel-up {
  0% {
    transform: translateY(80px);
  }
  100% {
    transform: translateY(-80px);
  }
}

.chatbot-logo--animated {
  overflow: hidden;
  border-radius: 50%;
}

.chatbot-logo--animated .msg--blue {
  animation: travel-up 1s infinite;
}
.chatbot-logo--animated .msg--yellow {
  animation: travel-up 0.7s infinite;
  animation-delay: 0.2s;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.chat__type_blinker {
  animation: blink 0.5s step-end infinite;
  width: 5px;
  height: 14px;
  background: black;
  display: inline-block;
}

@keyframes blinker {
  0% {
    opacity: .1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .1;
  }
}

.typing { position: relative; }
.typing span {
  content: '';
  animation: blinker 1.5s infinite;
  animation-fill-mode: both;
  height: 5px;
  width: 5px;
  background: rgba(47, 110, 177, 0.5);
  position: absolute;
  left:0;
  top:0;
  border-radius: 50%;
}
  
.typing span:nth-child(2) {
  animation-delay: .2s;
  margin-left: 8px;
}
  
.typing span:nth-child(3) {
  animation-delay: .4s;
  margin-left: 16px;
}

textarea {
  overflow: hidden;
}

textarea:focus, textarea:focus-visible {
  outline-color: #0058AB;
}